//Live keys
const APIROOT = "https://api.kolobox.ng";
const PAYSTACK_KEY = "pk_live_378b706593bd2d885b9bc12720740bb79d72de4d";
const FLUTTERWAVE_KEY = "FLWPUBK_TEST-e91f29aabd2abab8877188e79db539ba-X";
const KOLOFLEX_SUBACCOUNT = "ACCT_1lnhn6h9dnkivel";
const KOLOTARGET_SUBACCOUNT = "ACCT_j8r3p1iia5hlhmo";

const LIVE_KEYS = {
  APIROOT,
  PAYSTACK_KEY,
  FLUTTERWAVE_KEY,
  KOLOFLEX_SUBACCOUNT,
  KOLOTARGET_SUBACCOUNT
};

//localhost node js server
const APIROOT_LOCAL = "http://localhost:9200";

//Staging keys
const APIROOT_STAGING = "https://api-staging.kolobox.ng";
const PAYSTACK_KEY_TEST = "pk_test_6ab4aa429d840baeec8966b61aadc1998595a81d";
const FLUTTERWAVE_KEY_TEST = "FLWPUBK_TEST-e91f29aabd2abab8877188e79db539ba-X";
const KOLOFLEX_SUBACCOUNT_TEST = "ACCT_891fqxhuwx05wj6";
const KOLOTARGET_SUBACCOUNT_TEST = "ACCT_s5e7x4ijeift1dv";

const STAGING_KEYS = {
  APIROOT: APIROOT_STAGING,
  PAYSTACK_KEY: PAYSTACK_KEY_TEST,
  FLUTTERWAVE_KEY: FLUTTERWAVE_KEY_TEST,
  KOLOFLEX_SUBACCOUNT: KOLOFLEX_SUBACCOUNT_TEST,
  KOLOTARGET_SUBACCOUNT: KOLOTARGET_SUBACCOUNT_TEST
};

//export default process.env.NODE_ENV === 'production' ? STAGING_KEYS : LIVE_KEYS;
export default LIVE_KEYS;
